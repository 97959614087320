.hmXLyn {
    position: relative;
    margin-top: 72px;
}
.ijbeXh .nav-tit {
    bottom: 0px;
}
.dJteWA {
    position: relative;
    background-image: var(--curve-bg-1);
    background-repeat: no-repeat;
    background-size: 70% 100%;
    border-top-left-radius: 1.25rem;
    display: inline-block;
    white-space: nowrap;
}
.dJteWA .cont-txt {
    position: relative;
    height: 100%;
    line-height: 24px;
    left: 0px;
    z-index: 99;
    padding: 12px 2.75rem 0rem 1.25rem;
    font-size: 18px;
    color: var(--text-5);
    font-style: italic;
    font-weight: 800;
    font-family: FuturaBold;
    top: 0px;
}
.dJteWA .cont-txt b {
    color: rgb(231, 11, 108);
    font-style: italic;
}
.dJteWA::after {
    content: "";
    position: absolute;
    width: 40%;
    left: 69%;
    top: 0px;
    bottom: 0px;
    background: var(--curve-bg-img-1) 0% 0% / 100% 100% no-repeat;
}
.blJYGp .grid-list {
    background-color: var(--card-bg-1);
    padding: 12px 0.75rem 1.4375rem;
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
}
.cFDYcT {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: dense;
    gap: 1.5rem 0.9375rem;
}
.epfPPG.super-block {
    grid-column: 1 / 3;
}
.epfPPG {
    flex: 1 1 0%;
    position: relative;
    user-select: none;
}
.epfPPG.super-block .game-item-wrap {
    padding-top: 48%;
}
.dbJiKC {
    width: 100%;
    padding-top: 100%;
    position: relative;
    display: block;
    border-radius: 1.25rem;
    overflow: hidden;
    transform: translateZ(0px);
}
.dbJiKC .img {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease 0s;
    background: var(--card-bg-2);
}
.dbJiKC .img img {
    width: 100%;
    height: 100%;
}
.gVplre {
    position: relative;
    color: rgb(255, 255, 255);
    height: 1.5625rem;
    line-height: 1.5625rem;
    font-size: 0.75rem;
}
.gVplre .wrap {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    padding: 0.3125rem 1.875rem 0px 0.875rem;
    box-sizing: border-box;
}
.gVplre .item {
    height: 1.25rem;
    line-height: 1.25rem;
    color: var(--text-7);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.gVplre .help-btn-wrap button {
    position: absolute;
    width: 2.125rem;
    height: 2.125rem;
    right: 0.125rem;
    top: -0.1875rem;
    padding: 0.5rem;
    opacity: 0.6;
}
.hxODWG {
    width: 1.4em;
    height: 1.4em;
    fill: rgba(153, 164, 176, 0.6);
}
.dbJiKC .hover-mask {
    position: absolute;
    transition: all 0.3s linear 0s;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: 0.75rem;
    opacity: 0;
}
.dbJiKC .hover-mask .house-edge {
    color: var(--text-5);
    text-align: right;
    padding: 0.625rem 0.875rem 0px 0px;
}