@keyframes highlight {
  0% { filter: brightness(1) contrast(1); }
  50% { filter: brightness(1.5) contrast(1.2); }
  100% { filter: brightness(1) contrast(1); }
}


@keyframes whiteFlash {
  0% { filter: brightness(1) contrast(1); }
  50% { filter: brightness(1.5) contrast(1.2); }
  100% { filter: brightness(1) contrast(1); }
}
/* Add this to your CSS file */
@keyframes scale-up {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.scale-up {
  animation: scale-up 0.5s ease-in-out;
}
.animating {
  animation: whiteFlash 0.2s ease-in-out;
}



@keyframes fadeInWhitish {
  0% {
    opacity: 0;
    background-color: transparent;
  }
  20% {
    opacity: 1;
    background-color: transparent;
  }
  100% {
    opacity: 1;
  }
}

.number-grid {
  position: relative;
}

.number-grid::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
   /* background-color: rgba(0, 0, 0, 0.2); */
  /* backdrop-filter: blur(2px); */
  z-index: 1;
}

.selected {
  box-shadow: 0 0 0 2px #4CAF50;
  border-radius: 50%;
}


.number-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  font-size: 24px;
  font-weight: bold;
}


.number-grid-1 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  position: relative;
}

.number-circle {
  width: 100%;
  position: relative;
  height: 47px;
  width: 47px;
  margin: 6px 16px;
  cursor: pointer;
  /* transition: all 0.3s ease; */
}

.number-circle img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.selected {
  box-shadow: 0 0 0 2px #4CAF50;
  border-radius: 50%;
}

.dimmed {
  opacity: 0.5;
}

.highlighted {
  animation: blink 0.5s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

}

.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index:999

}

.high-priority-image {
  z-index: 9999; /* Ensure the image has a higher z-index than the overlay */
  position: relative; /* Ensure z-index is applied */
}


.countdown {
  font-size: 89px !important;
  color: white;
  font-weight: bold;
  z-index: 10;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1.5);
}


.bahis-container {
  position: relative;
  background: linear-gradient(to bottom, rgba(40, 112, 51, 0.6), rgba(14, 40, 23, 0.6));
  padding: 8px 16px;
  border-radius: 6px;
  display: inline-block;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 9999;
  top:-20px;
  
  /* Add these lines for the dark green border on top and bottom */
  border-top: 3px solid green;
  border-bottom: 3px solid green;
}

.bahis-container::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, 
    rgba(40, 112, 51, 0.1) 0%,
    rgba(40, 112, 51, 0) 15%,
    rgba(40, 112, 51, 0) 85%,
    rgba(40, 112, 51, 0.1) 100%
  );
  pointer-events: none;
}

.bahis-text {
  position: relative;
  color: white;
  font-family: Arial, sans-serif;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.zoom-container {
  width: 100px;
  overflow: hidden;
}

.zoom-image {
  width: 100%;
  object-fit: contain;
  animation: zoomIn 2s ease-out forwards;
}